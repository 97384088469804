import React from 'react'
import { Link } from 'gatsby'
import { Container, Row } from 'reactstrap'
import Seo from '../components/seo'

const NotFoundPage = () => (
  <section className="page-404">
    <Seo title="404 - Not Found! | Talk of the Town" />

    <Container>
      <Row>
        <p>ERRO 404 NOT FOUND</p>
        <p>
          Infelizmente não conseguimos encontrar essa página, que tal uma das
          opções abaixo?
        </p>
        <nav>
          <Link to="/">Home</Link>
          <Link to="/debates-in-english/">Debates in English</Link>
          <Link to="/talks-and-courses/">Talks & Courses</Link>
          <Link to="/debates-for-teachers/">Teachers & Schools</Link>
          <Link to="/pricing/">Pricing</Link>
          <Link to="/about-us/">About</Link>
          <Link to="/faq/">FAQ</Link>
          <Link to="/talk-to-us/">Talk to us</Link>
        </nav>
      </Row>
    </Container>
  </section>
)

export default NotFoundPage
